<template>
  <div class="wrapper pageBox">
    <div class="banner">
      <div class="banner-text">
        <img class="img" src="@/assets/img/wl.png" alt="">
        <div class="btn">了解更多中嘉资讯</div>
      </div>
    </div>
    <div class="conBox">
      <div v-if="notEmpty(monitor)" class="tabs">
        <div v-for="(item, index) in navIds" :key="index" class="li" @click="go(index, item)">
          <span class="text" :class="{'selected': lastIndex === index}">{{ item.name }}</span>
        </div>
      </div>
      <div class="search">
        <el-input v-model="queryParams.title" clearable placeholder="请输入关键词" />
        <i class="el-icon-search" @click="search" />
      </div>
      <div class="pushLabel">
        <div class="label">推荐标签：</div>
        <div
          v-for="(item, index) in labelList"
          :key="index"
          class="value"
          :class="queryParams.label === item ? 'selected' : ''"
          @click="clickLabel(item)"
        >{{ item }}</div>
      </div>
      <div class="listBox">
        <div v-for="(item, index) in tableData" :key="index" class="li" @click="goInfo(item)">
          <img :src="item.shortPic" class="li-img">
          <div class="li-title">{{ item.title }}</div>
          <div class="li-text">{{ item.desction }}</div>
          <div class="li-btm">
            <div class="date">{{ item.createDate ? item.createDate.substring(0, 10) : '' }}</div>
            <div class="btn">查看详情</div>
            <i class="el-icon-right" />
          </div>
        </div>
      </div>
      <div class="page">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="queryParams.limit"
          :current-page="queryParams.page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
// 接口
import { getNewsList } from '@/service/api';
export default {
  name: 'News',
  data() {
    return {
      // 查询参数
      queryParams: {
        page: 1, // 当前页
        limit: 8, // 每页显示条数
        navIds: [], // 通过id区分不同tab
        title: '', // 搜索文章关键词
        label: '' // 推荐标签搜索文章
      },
      tableData: [],
      lastIndex: 0,
      labelList: ['数字园区', '中标公告', '十四五'],
      navIds: [{ name: '全部' }],
      total: 0,
      timer: null
    };
  },
  computed: {
    monitor() {
      return this.$store.state.navIds;
    }
  },
  watch: {
    '$route.query.id': {
      immediate: false,
      handler() {
        if (this.$route.query.type !== '0') {
          let obj = null;
          this.navIds.forEach((item, index) => { if (Number(this.$route.query.type) === index) obj = item; });
          this.go(Number(this.$route.query.type), obj);
          return;
        }
      }
    },
    monitor: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== null) {
          this.navIds = [...this.navIds, ...val.child];
          if (this.$route.query.type !== '0') {
            let obj = null;
            this.navIds.forEach((item, index) => { if (Number(this.$route.query.type) === index) obj = item; });
            this.go(Number(this.$route.query.type), obj);
            return;
          }
          val.child.forEach((item, index) => {
            this.queryParams.navIds.push(item.id);
          });
          this.lastIndex = 0;
          this.getData();
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    async getData() {
      const res = await getNewsList(this.queryParams);
      if (res.code === 200) {
        this.tableData = res.page.list;
        this.total = res.page.totalCount;
      }
    },
    search() {
      this.queryParams.page = 1;
      this.getData();
    },
    clickLabel(item) {
      if (!this.notEmpty(this.queryParams.label) || this.queryParams.label !== item) {
        this.queryParams.label = item;
        this.search();
      } else {
        this.queryParams.label = '';
        this.search();
      }
    },
    go(index, item) {
      console.log('item.id', item.id);
      if (item.id) {
        this.queryParams.navIds = [item.id];
      } else {
        this.monitor.child.forEach((item, index) => {
          this.queryParams.navIds.push(item.id);
        });
      }
      this.lastIndex = index;
      this.queryParams.label = '';
      this.getData();
    },
    goInfo(item) {
      this.$router.push({
        path: '/news/info',
        query: {
          id: item.id,
          navId: item.navId
        }
      });
    },
    // 翻页
    handleCurrentChange(val) {
      this.queryParams.page = val;
      this.getData();
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  .banner {
    width: 100%;
    height: 720px;
    background: url('https://static.zhongjiadata.com/images/20250226/0fddc6e4bb0444eab0139468736e7989.png') no-repeat center center;
    background-size: cover;
    .banner-text {
      padding-top: 240px;
      width: 1384px;
      margin: 0 auto;
      .img {
        width: 360px;
        height: 64px;
        margin-bottom: 8px;
        object-fit: fill;
      }
      .btn {
        width: 229px;
        height: 48px;
        background: #3873ff;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 24px;
        font-weight: 400;
      }
    }
    .bannerText {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 48px;
        color: #fff;
        font-family: 'siyuan-medium' !important;
      }
      .btn {
        margin-top: 30px;
        width: 200px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #144fd1;
        color: #fff;
        font-size: 24px;
        border-radius: 30px;
        cursor: pointer;
      }
    }
  }
}
.conBox {

  .tabs {
    width: 100%;
    height: 80px;
    background: linear-gradient(180deg,#e1eeff, #ffffff 50%);
    .li {
      height: 80px;
      line-height: 80px;
      .text {
        border-bottom: none;
        width: 140px;
        display: block;
        &.selected {
          background: linear-gradient(180deg,rgba(255,255,255,0.00), rgba(26,88,235,0.20));
          border-bottom: 3px solid #2e61ff;
        }
      }
    }
  }
  .search {
    position: relative;
    width: 700px;
    margin: 0 auto;
    .el-input {
      border-radius: 26px;
      border: 2px solid #E7EAEF;
      overflow: hidden;
    }
    /deep/ .el-input__inner {
      padding-left: 42px;
      width: 620px;
      height: 48px;
      line-height: 48px;
      border: none;
      font-size: 18px;
      &::placeholder {
        color: #C5CBD3;
        font-size: 18px;
      }
    }
    /deep/ .el-input__clear {
      font-size: 30px;
      line-height: 46px;
      padding-right: 80px;
    }
    i {
      position: absolute;
      top: 10px;
      right: 44px;
      font-size: 30px;
      color: #666;
      cursor: pointer;
    }
  }
  .pushLabel {
    margin: 0 auto;
    margin-top: 24px;
    padding-left: 46px;
    width: 700px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #9299A5;
    font-size: 16px;
    margin-bottom: 60px;
    .label {
      margin-right: 24px;
      font-size: 18px;
      color: #333;
    }
    .value {
      margin-right: 30px;
      padding: 0 15px;
      height: 30px;
      line-height: 30px;
      background: #f6f6f6;
      border-radius: 17px;
      border: 1px solid #dfdfdf;
      color: #666;
      cursor: pointer;
      &.selected {
        color: #2E61FF;
      }
    }
  }
  .listBox {
    width: 1260px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .li {
      cursor: pointer;
      width: 420px;
      height: 428px;
      padding: 20px;
      margin-bottom: 40px;
      position: relative;
      &:hover {
        box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.10);
      }
      .li-img {
        width: 380px;
        height: 200px;
        margin-bottom: 22px;
      }
      .li-title {
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        font-weight: 600;
        margin-bottom: 12px;
      }
      .li-text {
        color: #666666;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 24px;
        max-height: 80px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .li-btm {
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        line-height: 20px;
        .date {
          font-size: 14px;
          color: #666;
          height: 20px;
          padding-right: 8px;
          border-right: 1px solid #dfdfdf;
          margin-right: 8px;
        }
        .btn {
         color: #2e61ff;
         font-size: 14px;
         margin-right: 6px;
        }
        i {
          font-size: 16px;
          color: #2e61ff;
        }
      }
      .rowLine {
        width: 54px;
        height: 1px;
        background: #D1D5DE;
      }
      .colLine {
        margin-top: 38px;
        width: 1px;
        height: 79px;
        background: #D1D5DE;
      }
      .left {
        margin-top: 26px;
        box-sizing: border-box;
        padding-left: 46px;
        width: 186px;
        .date {
          margin-bottom: 6px;
          font-size: 36px;
          color: #252B3A;
          font-weight: bold;
        }
        .year {
          margin-top: 10px;
          font-size: 18px;
          color: #B9BDC6;
          font-family: 'siyuan-medium' !important;
        }
      }
      .right {
        margin-top: 30px;
        margin-bottom: 20px;
        padding-left: 28px;
        flex: 1;
        .title {
          cursor: pointer;
          margin-bottom: 7px;
          font-size: 24px;
          color: #252B3A;
          font-family: 'siyuan-medium' !important;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          &:hover {
            color: #144fd1;
          }
        }
        .text {
          padding-right: 36px;
          font-size: 14px;
          color: #6A6E77;
          line-height: 24px;
          letter-spacing: .5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .labels {
          margin-top: 18px;
          display: flex;
          .label {
            margin-right: 16px;
            padding: 1px 8px;
            border: 1px solid #D1D5DE;
            color: #B9BDC6;
            font-size: 12px;
            border-radius: 3px;
          }
        }
      }
    }
  }
  .page {
    margin-top: 33px;
    margin-bottom: 250px;
  }
}
</style>
